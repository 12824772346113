import {useMemo} from 'react';
import {CA, DEFAULT_LOCALES_LIST, UAE, UK} from "../../helper/constantHelper";
import {useRouter} from "next/router";

function useMeta({ title, description, currLocale, urlCategory }) {

    const catViseOgImage = [
        'cover.jpg',
        'OG-Images/Essay-Writing-Service.webp',
        'OG-Images/Assignment-Writing-Service.webp',
        'OG-Images/Research-Paper-Writing-Service.webp',
        'OG-Images/Coursework-Writing-Service.webp',
        'OG-Images/Case-Study-Writing-Service.webp',
        'OG-Images/Dissertation-Writing-Service.webp',
        'OG-Images/Letter-Writing-Service.webp',
        'OG-Images/Resume_CV-Writing-Service.webp',
        'OG-Images/Homework-Help-Service.webp',
        'OG-Images/Article-Writing-Service.webp',
        'OG-Images/Personal-Statement-Writing-Service.webp',
        'OG-Images/Story-Writing-_-Editing-Service.webp',
        'OG-Images/Project-Help-Service.webp',
        'OG-Images/No.1-Writing-Service-Provider-in-the-US.webp',
        'OG-Images/Speech-Writing-Service.webp',
        'OG-Images/Proofreading-Service.webp',
        'OG-Images/Thesis-Writing-Service.webp',
        'OG-Images/Hire-the-Top-Writer-for-Your-Project.webp',
        'OG-Images/No.1-Writing-Service-Provider-in-the-US.webp',
        'OG-Images/No.1-Writing-Service-Provider-in-the-US.webp',
        'OG-Images/No.1-Writing-Service-Provider-in-the-US.webp'
    ];

    const catViseOgImageUK = [
        'cover.jpg',
        'OG-Images/uk/Essay-Writing-Service-UK.webp',
        'OG-Images/uk/Assignment-Writing-Service.webp',
        'OG-Images/uk/Research-Paper-Writing-Service.webp',
        'OG-Images/uk/Coursework-Writing-Service.webp',
        'OG-Images/uk/Case-Study-Writing-Service.webp',
        'OG-Images/uk/Dissertation-Writing-Service.webp',
        'OG-Images/uk/Letter-Writing-Service.webp',
        'OG-Images/uk/Resume_CV-Writing-Service.webp',
        'OG-Images/uk/Homework-Help-Service.webp',
        'OG-Images/uk/Article-Writing-Service.webp',
        'OG-Images/uk/Personal-Statement-Writing-Service.webp',
        'OG-Images/uk/Story-Writing-_-Editing-Service.webp',
        'OG-Images/uk/Project-Help-Service.webp',
        'OG-Images/uk/No.1-Writing-Service-Provider-in-the-UK.webp',
        'OG-Images/uk/Speech-Writing-Service.webp',
        'OG-Images/uk/Proofreading-Service.webp',
        'OG-Images/uk/Thesis-Writing-Service.webp',
        'OG-Images/uk/Hire-the-Top-Writer-in-the-UK.webp',
        'OG-Images/uk/No.1-Writing-Service-Provider-in-the-UK.webp',
        'OG-Images/uk/No.1-Writing-Service-Provider-in-the-UK.webp',
        'OG-Images/uk/No.1-Writing-Service-Provider-in-the-UK.webp'
    ];

    const catViseOgImageCA = [
        'cover.jpg',
        'OG-Images/ca/Essay-Writing-Service-CA.webp',
        'OG-Images/ca/Assignment-Writing-Service.webp',
        'OG-Images/ca/Research-Paper-Writing-Service.webp',
        'OG-Images/ca/Coursework-Writing-Service.webp',
        'OG-Images/ca/Case-Study-Writing-Service.webp',
        'OG-Images/ca/Dissertation-Writing-Service.webp',
        'OG-Images/ca/Letter-Writing-Service.webp',
        'OG-Images/ca/Resume_CV-Writing-Service.webp',
        'OG-Images/ca/Homework-Help-Service.webp',
        'OG-Images/ca/Article-Writing-Service.webp',
        'OG-Images/ca/Personal-Statement-Writing-Service.webp',
        'OG-Images/ca/Story-Writing-_-Editing-Service.webp',
        'OG-Images/ca/Project-Help-Service.webp',
        'OG-Images/ca/No.1-Writing-Service-Provider-in-the-Canada.webp',
        'OG-Images/ca/Speech-Writing-Service.webp',
        'OG-Images/ca/Proofreading-Service.webp',
        'OG-Images/ca/Thesis-Writing-Service.webp',
        'OG-Images/ca/Hire-the-Top-Writer-in-the-Canada.webp',
        'OG-Images/ca/No.1-Writing-Service-Provider-in-the-Canada.webp',
        'OG-Images/ca/No.1-Writing-Service-Provider-in-the-Canada.webp',
        'OG-Images/ca/No.1-Writing-Service-Provider-in-the-Canada.webp'
    ];

    const catViseOgImageUAE = [
        'cover.jpg',
        'OG-Images/uae/Essay-Writing-Service-UAE.webp',
        'OG-Images/uae/Assignment-Writing-Service.webp',
        'OG-Images/uae/Research-Paper-Writing-Service.webp',
        'OG-Images/uae/Coursework-Writing-Service.webp',
        'OG-Images/uae/Case-Study-Writing-Service.webp',
        'OG-Images/uae/Dissertation-Writing-Service.webp',
        'OG-Images/uae/Letter-Writing-Service.webp',
        'OG-Images/uae/Resume_CV-Writing-Service.webp',
        'OG-Images/uae/Homework-Help-Service.webp',
        'OG-Images/uae/Article-Writing-Service.webp',
        'OG-Images/uae/Personal-Statement-Writing-Service.webp',
        'OG-Images/uae/Story-Writing-_-Editing-Service.webp',
        'OG-Images/uae/Project-Help-Service.webp',
        'OG-Images/uae/No.1-Writing-Service-Provider-in-the-UAE.webp',
        'OG-Images/uae/Speech-Writing-Service.webp',
        'OG-Images/uae/Proofreading-Service.webp',
        'OG-Images/uae/Thesis-Writing-Service.webp',
        'OG-Images/uae/Hire-the-Top-Writer-in-the-UAE.webp',
        'OG-Images/uae/No.1-Writing-Service-Provider-in-the-UAE.webp',
        'OG-Images/uae/No.1-Writing-Service-Provider-in-the-UAE.webp',
        'OG-Images/uae/No.1-Writing-Service-Provider-in-the-UAE.webp'
    ];

    const router = useRouter();
    const metaDescription = useMemo(()=>{
        if(description && description !== '') return description;
        return '';
    },[description]);

    const metaName = useMemo(()=>{
        if(title && title !== '') return title;
        return ''
    },[title]);

    const metaRobots = useMemo(()=>{
        if(router && router.query && router.query.user_name) return "noindex,follow";
        return "index,follow";
    },[]);

    const metaOgUrl = useMemo(()=>{
        if(router && router.query && router.query.hasOwnProperty('serviceName'))
            return process.env.hostBaseUrl + (DEFAULT_LOCALES_LIST.includes(currLocale) ? '' : `/${currLocale}`) + '/' + router.query.serviceName;
        const localePath = (DEFAULT_LOCALES_LIST.includes(currLocale) ? '' : `/${currLocale}`) + (router.asPath && router.asPath === '/' ? '' : router.asPath)
        return process.env.hostBaseUrl + (router ? localePath : '')
    },[router, currLocale]);

    const metaOgTitle = useMemo(()=>{
        if(title && title !== '') return title;
        return '';
    },[title]);

    const metaOgDescription = useMemo(()=>{
        if(description && description !== '') return description;
        return '';
    },[description]);

    const metaOgImage = useMemo(()=>{
        if(router && router.asPath === '/coupons') return `${process.env.hostBaseUrl}/OG-Images/Coupons-OG-Image.png`;
        if(router && router.asPath === '/tools/free-plagiarism-checker') return `${process.env.hostBaseUrl}/OG-Images/free-plagiarism-checker.jpg`;
        let categoryWiseImage = 'cover.jpg';
        if(urlCategory && urlCategory !== ''){
            if(currLocale && currLocale === UK)
                categoryWiseImage = catViseOgImageUK[parseInt(urlCategory)];
            else if(currLocale && currLocale === CA)
                categoryWiseImage = catViseOgImageCA[parseInt(urlCategory)];
            else if(currLocale && currLocale === UAE)
                categoryWiseImage = catViseOgImageUAE[parseInt(urlCategory)];
            else categoryWiseImage = catViseOgImage[parseInt(urlCategory)];
        }
        return `${process.env.hostBaseUrl}/${categoryWiseImage}`;
    },[router, currLocale]);

    const metaTwitterTitle = useMemo(()=>{
        if(title && title !== '') return title;
        return '';
    },[]);

    const metaTwitterDescription = useMemo(()=>{
        if(description && description !== '') return description;
        return ''
    },[description]);

    const metaTwitterImage = useMemo(()=>{
        if(router && router.asPath === '/coupons') return `${process.env.hostBaseUrl}/OG-Images/Coupons-OG-Image.png`;
        if(router && router.asPath === '/tools/free-plagiarism-checker') return `${process.env.hostBaseUrl}/OG-Images/free-plagiarism-checker.jpg`;
        let categoryWiseImage = 'cover.jpg';
        if(urlCategory && urlCategory !== ''){
            if(currLocale && currLocale === UK) categoryWiseImage = catViseOgImageUK[parseInt(urlCategory)];
            else categoryWiseImage = catViseOgImage[parseInt(urlCategory)];
        }
        return `${process.env.hostBaseUrl}/${categoryWiseImage}`;
    },[router, urlCategory]);

    const metaTwitterUrl = useMemo(()=>{
        if(router && router.query && router.query.hasOwnProperty('serviceName')){
            return process.env.hostBaseUrl + (DEFAULT_LOCALES_LIST.includes(currLocale) ? '' : `/${currLocale}`) + '/' + router.query.serviceName;
        }
        const localePath = (DEFAULT_LOCALES_LIST.includes(currLocale) ? '' : `/${currLocale}`) + (router.asPath && router.asPath === '/' ? '' : router.asPath);
        return process.env.hostBaseUrl + (router ? localePath : '');
    },[]);

    return {
        metaDescription,
        metaName,
        metaRobots,
        metaOgUrl,
        metaOgTitle,
        metaOgDescription,
        metaOgImage,
        metaTwitterTitle,
        metaTwitterDescription,
        metaTwitterImage,
        metaTwitterUrl
    };
}

export default useMeta;
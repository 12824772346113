import React from "react";
import {useRouter} from "next/router";

import Head from "next/head";
import useIntercomWidget from "../../helper/useIntercomWidget";
import useMeta from "./useMeta";

function Meta(props) {

    useIntercomWidget();
    const router = useRouter();
    const { title } = props;
    const {
        metaDescription,
        metaName,
        metaRobots,
        metaOgUrl,
        metaOgTitle,
        metaOgDescription,
        metaOgImage,
        metaTwitterTitle,
        metaTwitterDescription,
        metaTwitterImage,
        metaTwitterUrl
    } = useMeta(props);

    return (
        <>
            <Head>
                <meta charSet="utf-8" />

                {/* <title>{propsData && propsData.title && propsData.title !== '' ? propsData.title : 'CheapestEssay Writing Service by Professional Essay Writer'}</title> */}
                <title>{title && title !== '' ? title : ''}</title>

                <meta name="author" content="CheapestEssay Team" />
                
                {/* <meta name="title" content={propsData && propsData.title && propsData.title !== '' ? propsData.title : 'CheapestEssay Writing Service by Professional Essay Writer'} /> */}
                <meta name="title" content={title && title !== '' ? title : ''} />

                {/* <meta name="description" content={propsData && propsData.description && propsData.description !== '' ? propsData.description : 'CheapestEssay writing service: Hire professional essay writers &amp; get your 100 % plagiarism-free paper delivered on time. Avail of 15% discount on first order.'} /> */}
                <meta name="description" content={metaDescription} />

                {/* Google / Search Engine Tags  */}
                {/* <meta itemProp="name" content={propsData && propsData.title && propsData.title !== '' ? propsData.title : 'CheapestEssay Writing Service by Professional Essay Writer'} /> */}
                <meta itemProp="name" content={metaName} />

                {/* <meta itemProp="description" content={propsData && propsData.description && propsData.description !== '' ? propsData.description : 'CheapestEssay writing service: Hire professional essay writers &amp; get your 100 % plagiarism-free paper delivered on time. Avail of 15% discount on first order.'} /> */}
                <meta itemProp="description" content={metaDescription} />

                <meta itemProp="image" content={process.env.hostBaseUrl + "/cover.jpg"} />

                {router && router.pathname && router.pathname === "/404" ? null :
                    <meta name="robots" content={metaRobots} />
                }

                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"/>
                <meta name="theme-color" content="#000000" />

                <meta property="og:url" content={metaOgUrl} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={metaOgTitle} />
                <meta property="og:description" content={metaOgDescription} />
                <meta property="og:image" content={metaOgImage} />
                {/* <meta data={router.asPath} property="og:image" content={`${process.env.hostBaseUrl}/${propsData && propsData.urlCategory && propsData.urlCategory !== '' ? catViseOgImage[parseInt(propsData.urlCategory)] : 'cover.jpg'}`} /> */}

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={metaTwitterTitle} />
                {/* <meta name="twitter:description" content={propsData && propsData.description && propsData.description !== '' ? propsData.description : 'CheapestEssay writing service: Hire professional essay writers &amp; get your 100 % plagiarism-free paper delivered on time. Avail of 15% discount on first order.'} /> */}
                <meta name="twitter:description" content={metaTwitterDescription} />
                {/* <meta name="twitter:image" content={CoverPic} /> */}
                <meta property="twitter:image" content={metaTwitterImage} />
                <meta name="twitter:site" content="@cheapestessay" />
                <meta name="twitter:domain" content={process.env.hostBaseUrl} />
                {/* <meta name="twitter:url" content={process.env.hostBaseUrl} /> */}
                <meta property="twitter:url" content={metaTwitterUrl} />
                <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap" rel="stylesheet" />
                <meta name="twitter:url" content={process.env.hostBaseUrl} />

            </Head>
            {/* End Google Tag Manager  */}
        </>
    );
}

export default Meta;
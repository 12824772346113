import React, {useEffect} from 'react';

const useIntercomWidget = () => {

    const setIntercom = () => {
        if (typeof window !== "undefined") {
            let w = window;
            let ic = w.Intercom;
            if (typeof ic === "function") {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
                let d = document;
                let i = function () {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                let l = function () {
                    let s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/je6f9lsz';
                    let x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                };
               /* if (w.attachEvent) {
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                } */
                l();
                window.Intercom('boot', {
                    app_id: 'je6f9lsz'
                });
            }
        }
    }
    const intercomLoader = () => {
        setIntercom();
        window.removeEventListener('scroll', intercomLoader)
    }
    useEffect(()=>{
        window.addEventListener('scroll', intercomLoader)
    },[])
    /*useEffect(()=>{
        setIntercom()
    }, [])*/
    return { };
}

export default useIntercomWidget;